<template>
  <div v-if="customerAccounts.length > 0">
    <div class="dialog">
      <div v-if="!isAlertDialogOpenTwoConfirm" class="dialog-content">
        <h2 class="dialog-title">로그인 경고</h2>
        <p class="dialog-message">
          현재 {{ customerAccounts.length }}개의 계정 모두 사용이 불가합니다.<br/>
          선택한 계정을 강제로 로그아웃시켜, 해당 계정으로 로그인할 수 있습니다.
        </p>
        <div class="table">
          <div class="header td">
            <div class="tr">ID</div>
            <div class="tr">부서/팀</div>
            <div class="tr">사용자명</div>
            <div class="tr">접속일시</div>
            <div class="tr">접속불가사유</div>
          </div>
          <div class="td" v-for="(customerAccount, index) in customerAccounts" v-bind:key="index" @click.stop="customerAccount.statusCode === 0 && onClickLine(index)">
            <div class="tr">
              <span :class="{'material-symbols-outlined': true, 'radio': true, 'disable': customerAccount.statusCode > 0}">{{ customerAccount.statusCode > 0 || ckeckIndex === index ? "radio_button_checked" : "radio_button_unchecked"}}</span>
              {{ customerAccount.ubistUserId }}
            </div>
            <div class="tr">{{ formattedDate(customerAccount.loginTime) !== "-" ? customerAccount.userGroup : "-" }}</div>
            <div class="tr">{{ formattedDate(customerAccount.loginTime) !== "-" ? customerAccount.userName : "-" }}</div>
            <div class="tr">{{ formattedDate(customerAccount.loginTime) }}</div>
            <div class="tr">{{ ["사용중", "비밀번호 불일치", "계정 오류"][customerAccount.statusCode] }}</div>
          </div>
        </div>
        <div class="dialog-actions">
          <button class="btn dialog-confirm disable" @click="logouts">
            선택한 계정 로그아웃하기
          </button>
        </div>
      </div>
    </div>  
    <div class="dialog-overlay"></div>
  </div>
  <div v-else class="title-box">
    <div class="dialog"></div>
  </div>
  <AlertDialogTwoConfirm
    v-if="isAlertDialogOpenTwoConfirm"
    :maxWidthOption="alertDialogTwoConfirm.maxWidthOption"
    :title="alertDialogTwoConfirm.title"
    :message="alertDialogTwoConfirm.message"
    :focus="alertDialogTwoConfirm.focus"
    :confirmLabel="alertDialogTwoConfirm.confirmLabel"
    :confirm2Label="alertDialogTwoConfirm.confirm2Label"
    @confirm="alertDialogTwoConfirm.afterConfirm"
    @confirm2="alertDialogTwoConfirm.afterConfirm2"
  />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "@/store/common";
import ubistApi from "@/lib/ubistApi";
import { storeToRefs } from "pinia";
import { getUser, silent, logout, logoutRemoveUser} from "@/lib/loginService";
// import { getUser, silent, logout, logoutRemoveUser , login} from "@/lib/loginService";
import AlertDialogTwoConfirm from "@/components/AlertDialogTwoConfirm.vue";

export default {
  name: "Login",
  props: ['productType'],
  components: {
    AlertDialogTwoConfirm,
  },
  setup(props) {
    // console.log('props.productType', props.productType); // 1
    const router = useRouter();
    const commonStore = useCommonStore();
    const { user, userServiceList, productType, isAlertDialogOpenTwoConfirm, alertDialogTwoConfirm } = storeToRefs(commonStore);
    const { t } = useI18n();
    const result = ref();
    const ckeckIndex = ref(-1);
    const customerAccounts = ref([]);
    const acccounts = ref([]);

    // AlterDialog after Event - 등록하기
    const registUserAlertDialog = () => {
      // isAlertDialogOpenTwoConfirm.value = false;
      logoutRemoveUser();
      window.location.href = process.env.VUE_APP_TEAM_UBIST_AUTH + "/Account/UbistAccount";
    };

    // AlterDialog after Event - 닫기
    const closeAlertDialog = () => {
      // isAlertDialogOpenTwoConfirm.value = false;
      logout();
    };

    const formattedDate = computed(() => {
      return (data) => {
        try {
          const date = new Date(data);
          if (isNaN(date.getTime()) || date.getTime() === -62135627272000) return "-";

          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const hour = String(date.getHours()).padStart(2, "0");
          const minute = String(date.getMinutes()).padStart(2, "0");

          return `${year}-${month}-${day} ${hour}:${minute}`;
        } catch (error) {
          return "-";
        }
      };
    });

    const showPopupNoProductTypeUser = () => {
      alertDialogTwoConfirm.value = {
        maxWidthOption: "",
        title: "",
        message: t("message.need_register_ubist_account"),
        focus: "",
        confirmLabel: t("button.register"),
        confirm2Label: t("button.close"),
        afterConfirm: registUserAlertDialog,
        afterConfirm2: closeAlertDialog,
      };
      // console.log('alertDialogTwoConfirm', alertDialogTwoConfirm.value)
      isAlertDialogOpenTwoConfirm.value = true;
    };

    const setUser = async (isNeedDelete) => {
      // console.log('setUser user', user.value);
      if (user.value)
      {
        const profile = user.value.profile;
        // console.log('setUser profile', profile);
        if (profile)
        {
          if (profile.ubist_accounts)
          {
            const userIds = JSON.parse(profile.ubist_accounts);
            acccounts.value = userIds;
            if (!userIds || !Array.isArray(userIds))
            {
              showPopupNoProductTypeUser();
            }
            else
            {
              const filteredStatusCheck = userIds.filter(user => user.ProductType == localStorage.getItem('productType') && user.StatusCode == 0);
              // console.log('filteredStatusCheck', filteredStatusCheck);
              if (filteredStatusCheck.length === 0)
              {
                showPopupNoProductTypeUser();
              }

              const filtered = userIds.filter(user => user.ProductType == localStorage.getItem('productType'));
              // console.log('filtered', filtered);
              if (filtered.length === 0)
              {
                showPopupNoProductTypeUser();
              }
              else
              {
                if (isNeedDelete.value)
                {
                  // console.log("isNeedDelete.value", isNeedDelete.value);
                  await ubistApi.getUserLogoutNotCheck(user.value.access_token);
                }
                result.value = await ubistApi.getUserLogin(localStorage.getItem('productType'), user.value.access_token);

                // console.log('getUserLogin', result.value);
                // if (!result.value) {
                //   console.log('getUserLogin 2', result.value);
                //   silent().then((response) => {
                //     console.log('silent 2', response);
                //     user.value = response;
                //     setUser();
                //   }).catch(err => {
                //     console.log('silent 2', err);
                //     window.location.href = process.env.VUE_APP_TEAM_UBIST;
                //   });
                //   return;
                // }

                if (result.value.errorCode === 0) // Success
                {
                  const userInfo = userIds.filter(user => user.UBISTUserID == result.value.data.accountId);

                  // console.log('userInfo', userInfo);
                  // if (userInfo.filter(user => user.ProductType === 2).length > 0) {
                  //   localStorage.setItem('productType', 2);
                  // } else {
                  //   localStorage.setItem('productType', 1);
                  // }

                  const lProductType = localStorage.getItem('productType');
                  productType.value = lProductType;
                  userServiceList.value = await ubistApi.getServices(lProductType);
                  window.location.href = '/';
                  // router.push('/');
                } else if (result.value.errorCode === 101) { // Login Fail 이미
                  // console.log(result.value);
                  customerAccounts.value = result.value.data;
                  // console.log('fail - customerAccounts', customerAccounts.value);
                } else if (result.value.errorCode === 102) { // Login Block (IP 차단)
                  commonStore.openAlertDialog({
                    message: t("message.IP_block"),
                    afterEvent: () => window.location.href = process.env.VUE_APP_TEAM_UBIST,
                  });
                }
              }
            }
          }
        }
      }
    }

    // watch(user, async () => {
    //   console.log('call', user);
      
    // });

    onMounted(async () => {
      // console.log('onMounted');
      const isNeedDelete = ref(false);
      // 이미 열려져있는 서비스(기능)과 다른 ProductType 을 실행 했을 경우
      if (localStorage.getItem('productType') && props.productType && localStorage.getItem('productType') != props.productType)
      {
        // 1. 기존 Session 삭제
        isNeedDelete.value = true;
        // console.log('getUserLogout 필요');
      }

      if (props.productType)
      {
        localStorage.setItem('productType', props.productType);
        productType.value = localStorage.getItem('productType');
        // console.log('productType.value', productType.value);
      }

      // console.log('process.env.VUE_APP_TEAM_UBIST_AUTH', process.env.VUE_APP_TEAM_UBIST_AUTH);
      // console.log('${window.location.origin}/refresh', `${window.location.origin}/refresh`);
      
      await silent().then().catch(() => {
          window.location.href = process.env.VUE_APP_TEAM_UBIST;
        }
      );
      await getUser().then((res) => {
        // console.log('getUser', res);
        if (res) {
          user.value = res;
          setUser(isNeedDelete);
        } else {
          // console.log('getUser');
          silent().then((response) => {
            // console.log('silent', response);
            user.value = response;
            setUser(isNeedDelete);
          }).catch(err => {
            // console.log('silent', err);
            // login();
            window.location.href = process.env.VUE_APP_TEAM_UBIST;
          })
        }
      })
    });

    const logouts = async() => {
      if (ckeckIndex.value > -1)
      {
        if (user.value)
        {
          // console.log('logouts - user.value', user.value);
          const profile = user.value.profile;
          if (profile)
          {
            if (profile.ubist_accounts)
            {
              const userIds = acccounts.value;
              
              const selectAccount = customerAccounts.value[ckeckIndex.value];
              result.value = await ubistApi.getUserLogoutAndLogin(localStorage.getItem('productType'), selectAccount.sid);

              if (result.value.errorCode === 0) // Success
              {
                const userInfo = userIds.filter(user => user.UBISTUserID == result.value.data.accountId);

                // if (userInfo.filter(user => user.ProductType === 2).length > 0) {
                //   localStorage.setItem('productType', 2);
                // } else {
                //   localStorage.setItem('productType', 1);
                // }
                
                const lProductType = localStorage.getItem('productType');
                productType.value = lProductType;
                // console.log('logout And Login - productType.value', productType.value);
                userServiceList.value = await ubistApi.getServices(lProductType);
                window.location.href = '/';
                // router.push('/');
              } else {
                window.location.href = process.env.VUE_APP_TEAM_UBIST;
              }
            }
          }
        }
      }
    }

    const onClickLine = (index) => {
      console.log('onClickLine', index)
      ckeckIndex.value = index;
    }

    return {
      onClickLine,
      logouts,
      ckeckIndex,
      customerAccounts,
      formattedDate,

      //AlertDialog
      isAlertDialogOpenTwoConfirm,
      alertDialogTwoConfirm,
      registUserAlertDialog,
      closeAlertDialog,
    };
  }
};
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.dialog-content {
  background-color: #fff;
  padding: 20px 30px;
  width: max-content;
  border-radius: 16px; /* 20230622 수정 */
  box-shadow: 0 12px 24px 4px rgba(0, 0, 0, 0.25); /* 20230622 수정 */
}
.dialog-title {
  font-size: 22px; /* 20230622 수정 */
  font-weight: 700; /* 20230622 수정 */
  margin-bottom: 10px;
}
.dialog-message {
  font-size: 15px; /* 20230622 수정 */
  line-height: 1.5; /* 20230622 수정 */
  white-space: pre-wrap;
  margin-bottom: 20px;
}
.dialog-actions {
  padding: 20px 0 0 0;
  text-align: center;
}
.dialog-confirm {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.dialog-confirm.disable {
  color: #888;
  background-color: #f9f9f9;
  border: var(--border-line);
  border-radius: var(--border-radius);
}

.table {
  width: max-content;
}
.td {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.tr {
  width: 150px;

  padding: 6pt 9.75pt 6pt 9.75pt;

  text-align: center;
  vertical-align: middle;

  color: #444444;
  font-family: 'Pretendard-Regular';
  font-size: 10pt;

  border-style: solid;
  border-width: 0pt 1pt 1pt 0pt;
  border-color: #000000 #e9eaeb #e9eaeb #000000;
}
.header {
  border-top: 1.5pt solid #000000;
}
.header.td {
  cursor: default;
}
.header .tr {
  background-color: #f9f9f9;
}

.radio {
  font-size: 11pt;
  vertical-align: middle;
}
.radio.disable {
  color: #aaa;
}
</style>