<template>
  <div class="condition">
    <div :class="{ source: true, active: showContent !== null }">
      <div class="title"></div>
      <div class="source-content">
        <div class="left">
          <div class="search-bar">
            <div class="search-group">
              <input
                style="width: 71%"
                :placeholder="$t('label.search_word')"
                v-model="searchTextVal"
                type="text"
                @keydown.enter="fnSearch(showContent.code, showContent.type)"
              />
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearch(showContent.code, showContent.type)"
              >
                {{ $t("button.search") }}
              </button>
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearchClean(showContent.code, showContent.type)"
              >
                {{ $t("button.searchClean") }}
              </button>
            </div>
            <!-- <p style="font-size: 14px; padding-left: 10px; color: red">
              {{ $t("label.notice_combination_therapy2") }}
            </p> -->
            <div class="btn-group">
              <!--분석결과 -->
              <!-- <button
                class="btn"
                style="margin-left: 5px"
                v-if="iframe !== ''"
                @click="onBtnDisable()"
                :disabled="btnDisable"
              >
                {{ $t("button.prev_result") }}
              </button> -->
              <!-- 20230704 분석결과 아이콘으로 변경 -->
              <button
                style="margin-left: 5px"
                v-if="iframe !== ''"
                @click="onBtnDisable()"
                :disabled="btnDisable"
              >
                <span
                  class="btn material-symbols-outlined"
                  style="
                    font-variation-settings: 'wght' 300;
                    font-size: 22px;
                    line-height: 30px;
                    padding: 0 5px;
                    color: #8c8c8c;
                  "
                  >overview
                </span>
              </button>

              <!-- 제품일 경우 만 상세 검색 버튼  -->
              <button
                style="margin-left: 5px"
                class="btn"
                v-show="
                  showContent != null &&
                  constants.CONSTANT_PRODUCT_CODE.includes(showContent.code) &&
                  parentMenuCode !== constants.CONSTANT_MENU_SOB2_CODE
                "
                @click="openProductModal"
              >
                {{ $t("button.detailed_search") }}
              </button>
              <!-- 20230612 옵션버튼 변경 { -->
              <button
                :class="{ 'disabled': parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE }"
                style="margin-left: 5px"
                @click="openModal"
                :disabled="parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE"
              >
                <span
                  class="btn material-symbols-outlined"
                  :style="{ 
                    'font-variation-settings': `'wght' 300`,
                    'font-size': '22px',
                    'line-height': '30px',
                    'padding': '0 5px',
                    'color': parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE ? '#1010104d' :  '#8c8c8c',
                    'backgroundColor': parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE ? '#ebebeb' : '#fff',
                    'background-image': 'none',
                  }"
                  >settings</span
                >
              </button>
              <!-- } 20230612 옵션버튼 변경 -->
            </div>
          </div>
          <SearchContent v-if="showContent?.list?.length > 0" 
            :multiple="true"
            :content="showContent" 
            :searchTextVal="searchTextVal"
            :calendar="showContent?.code === constants.CONSTANT_PERIOD_WEEKLY_CODE"
            @change="onChange"
            @ondblclick="() => combine?.list?.length === 0 ? onClick() : onClickBottom()"
            @enter="() => onClick()"
            @onContextmenu="showContextMenuItem"
            />
          <div v-else class="search-content">
            <div class="search-content">
              <div class="search-content-sub">
                <div class="selected">
                  <div class="header">&nbsp;</div>
                  <select multiple class="select-empty">
                    <option></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- 기간을 위한 ContextMenu -->
          <div
            v-show="isContextMenuVisibleItem"
            id="contextMenuItem"
            class="context-menu"
            :style="{
              top: contextMenuPositionItem.y + 'px',
              left: contextMenuPositionItem.x + 'px',
            }"
            tabindex="0"
            @focusout.stop="focusout"
          >
            <ul>
              <li @click="putItem($event)">
                <button id="contextPutItem" :disabled="combine?.list?.length > 0">
                  {{ $t("button.add") }}
                </button>
              </li>
            </ul>
            <ul v-show="parentMenuCode !== constants.CONSTANT_MENU_SOB2_CODE" @click="combinePutItem($event)">
              <li>
                <button  id="contextMenuCombinePutItem">
                  {{ $t("button.summation") }}
                </button>
              </li>
            </ul>
            <ul v-show="isContextMenuButtonMore">
              <li @click="putMAT($event, 'CUR')">
                <button id="buttonCurrent"></button>
              </li>
            </ul>
            <ul v-show="isContextMenu3MRollingButtonMore">
              <li @click="putMAT($event, '3MRolling')">
                <button id="button3MRolling"></button>
              </li>
              <li v-show="isContextMenuButtonMore" @click="putMAT($event, '3MRollingLately')">
                <button id="button3MRollingLately"></button>
              </li>
            </ul>
            <ul v-show="isContextMenuButtonMore">
              <li @click="putMAT($event, 'MAT')">
                <button id="buttonMAT"></button>
              </li>
              <li @click="putMAT($event, '2MAT')">
                <button id="button2MAT"></button>
              </li>
              <li @click="putMAT($event, '3MAT')">
                <button id="button3MAT"></button>
              </li>
              <li @click="putMAT($event, '5MAT')">
                <button id="button5MAT"></button>
              </li>
            </ul>
          </div>
          <!-- 연령 커스텀 데이터 입력 -->
          <DirectInputAge
            v-if="
              showContent?.code !== null &&
              constants.CONSTANT_AGE_CODE.includes(showContent?.code) &&
              constants.CONSTANT_MENU_WEEKLY_CODE !== parentMenuCode
            "
            v-bind:parentMenu="parentMenu"
            v-bind:parentMenuCode="parentMenuCode"
            v-bind:subContentCode="constants.CONSTANT_AGE_DETAIL_CODE[constants.CONSTANT_AGE_CODE.indexOf(showContent?.code)]"
          ></DirectInputAge>
          <!-- 1회 투약량 커스텀 데이터 입력 -->
          <DirectInputOneQuantity
            v-if="
              showContent?.code !== null &&
              showContent?.code === constants.CONSTANT_ONEQUANTITY_CODE
            "
            v-bind:parentMenu="parentMenu"
            v-bind:parentMenuCode="parentMenuCode"
          ></DirectInputOneQuantity>
          <!-- 1회 투약횟수 커스텀 데이터 입력 -->
          <DirectInputPerDay
            v-if="
              showContent?.code !== null &&
              showContent?.code === constants.CONSTANT_PERDAY_CODE
            "
            v-bind:parentMenu="parentMenu"
            v-bind:parentMenuCode="parentMenuCode"
          ></DirectInputPerDay>
          <!-- 총 처방일수 커스텀 데이터 입력 -->
          <DirectInputTotalDay
            v-if="
              showContent?.code !== null &&
              showContent?.code === constants.CONSTANT_TOTALDAY_CODE
            "
            v-bind:parentMenu="parentMenu"
            v-bind:parentMenuCode="parentMenuCode"
          ></DirectInputTotalDay>

          <!-- 20230612 스타일 추가 { -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 5px;
            "
          >
            <!-- } 20230612 스타일 추가 -->
            <!-- 선택하지 않으면 부분 -->
            <!-- 기준 항목 코드(Combination Ⅱ ) 일경우 선택하지 않으면 영역 미표시 -->
            <div
              v-if="
                constants.CONSTANT_BASE_ITEM_CODES.includes(
                  selectedContent?.code
                )
              "
            >
              <label
                style="
                  color: var(--primary-color);
                  font-size: 13px;
                  padding-right: 5px;
                  display: inline-block;
                "
              >
              </label>
            </div>
            <div
              v-if="
                !constants.CONSTANT_METRICS_CODES.includes(
                  selectedContent?.code
                ) &&
                !constants.CONSTANT_BASE_ITEM_CODES.includes(
                  selectedContent?.code
                ) &&
                showContent?.subContents?.length > 0
              "
            >
              <!-- 20230612 스타일 추가 { -->
              <!-- color: var(--primary-color); -->
              <label
                style="
                  /* 230717 수정 : 일감 162939 */
                  /* color: var(--primary-color); */
                  font-size: 13px;
                  padding-right: 5px;
                  display: inline-block;
                "
              >
                {{ $t("label.choose_not1") }}
              </label>
              <!-- } 20230612 스타일 추가 -->
              <select
                :id="'choose_' + showContent.code"
                :disabled="
                  selectedContent?.list?.filter(
                    (list) =>
                      list.type === 0 ||
                      list.type === 1 ||
                      list.type === 2 ||
                      list.type === 3
                  ).length > 0
                "
                @change="onChooseChange($event, 'choose', showContent.code)"
                v-model="chooseSelectedOption"
              >
                <!-- 성분 / 병용 성분 일때 만 전체 추가 시작-->
                <option
                  v-if="
                    constants.CONSTANT_MOLECULE_CODE.includes(selectedContent?.code) ||
                    constants.CONSTANT_COMBI_MOLECULE_CODE ===
                      selectedContent?.code
                  "
                  :value="
                    constants.CONSTANT_MOLECULE_CODE.includes(selectedContent?.code)
                      ? constants.CONSTANT_MOLECULE_TOTAL_CODE[constants.CONSTANT_MOLECULE_CODE.indexOf(selectedContent?.code)]
                      : constants.CONSTANT_COMBI_MOLECULE_TOTAL_CODE
                  "
                  name="성분 전체"
                  englishName="All"
                >
                  {{ $t("label.molecule_total") }}
                </option>
                <option
                  v-if="
                    constants.CONSTANT_MOLECULE_CODE.includes(selectedContent?.code) ||
                    constants.CONSTANT_COMBI_MOLECULE_CODE ===
                      selectedContent?.code
                  "
                  :value="
                    constants.CONSTANT_MOLECULE_CODE.includes(selectedContent?.code)
                      ? constants.CONSTANT_MOLECULE_VOLUME_CODE[constants.CONSTANT_MOLECULE_CODE.indexOf(selectedContent?.code)]
                      : constants.CONSTANT_COMBI_MOLECULE_VOLUME_CODE
                  "
                  name="성분(용량) 전체"
                  englishName="All(s)"
                >
                  {{ $t("label.molecule_volume_total") }}
                </option>
                <!-- 성분 / 병용 성분 일때 만 전체 추가 끝-->

                <option
                  v-for="(s, i) in showContent.subContents"
                  v-show="s.code !== constants.CONSTANT_SPECIALTY_DETAIL_CODE"
                  :value="s.code"
                  :name="s.name"
                  :englishName="s.englishName"
                  v-bind:key="(s, i)"
                >
                  {{
                    this.$i18n.locale == "en"
                      ? s.englishName || s.name + "(en)"
                      : s.name
                  }}
                </option>
              </select>
              <!-- type : 1, 3(Generic 제외) 이고 type : 2 중 진료과 일 경우는 전체/Top/Bottom -->
              <!-- type=2 이고 진료과가 아닐 경우는 전체 disabled  -->
              <!-- type=3 인 Generic 일 떄 전체 disabled  -->
              <div class="rank_group">
                <input 
                  v-if="
                    [1, 3].includes(showContent.type) && !constants.CONSTANT_TOP_BOTTOM_DISABLED.includes(showContent.code)
                    || constants.CONSTANT_TOP_BOTTOM_ENABLED.includes(showContent.code)
                  "
                  @keydown.enter="(event) => {onChooseChange(event, 'rank_custom', showContent.code), event.target.blur()}"
                  @focusout="onChooseChange($event, 'rank_custom', showContent.code)"
                  :value="rankSelectedOption || (this.$i18n.locale === 'en' ? 'Total' : '전체')"
                />
                <select
                  v-if="
                    [1, 3].includes(showContent.type) && !constants.CONSTANT_TOP_BOTTOM_DISABLED.includes(showContent.code)
                    || constants.CONSTANT_TOP_BOTTOM_ENABLED.includes(showContent.code)
                  "
                  style="margin-left: 5px; width: 100px"
                  :id="'rank_' + showContent.code"
                  :disabled="
                    selectedContent?.list?.filter(
                      (list) =>
                        list.type === 0 ||
                        list.type === 1 ||
                        list.type === 2 ||
                        list.type === 3
                    ).length > 0
                  "
                  @change="onChooseChange($event, 'rank', showContent.code)"
                  v-model="rankSelectedOption"
                >
                  <option
                    v-for="(code, i) in topBottomCodeMapping"
                    v-bind:key="(code, i)"
                    :value="code.value"
                    :name="code.name"
                    :englishName="code.englishName"
                  >
                    {{
                      this.$i18n.locale == "en"
                        ? code.englishName || code.name + "(en)"
                        : code.name
                    }}
                  </option>
                </select>
                <select
                  v-else
                  style="margin-left: 5px; width: 100px"
                  :id="'rank_' + showContent.code"
                  @change="onChooseChange($event, 'rank', showContent.code)"
                  v-model="rankSelectedOption"
                  disabled
                >
                  <option value="" name="전체" englishName="Total">{{ this.$i18n.locale === "en" ? "Total" : "전체" }}</option>
                </select>
              </div>
              <label
                style="
                  font-size: 13px;
                  padding-left: 5px;
                  display: inline-block;
                "
                >{{ $t("label.choose_not2") }}</label
              >
            </div>
            <div
              v-if="
                !constants.CONSTANT_METRICS_CODES.includes(
                  selectedContent?.code
                ) && showContent?.subContents?.length > 0
                  && parentMenuCode !== constants.CONSTANT_MENU_SOB2_CODE
              "
            >
              <div id="divContentSum">
                <input
                  type="checkbox"
                  id="contentSum"
                  :disabled="
                    !checkViewYn &&
                    selectedContent?.list?.filter(
                      (list) =>
                        list.type === 0 ||
                        list.type === 1 ||
                        list.type === 2 ||
                        list.type === 3
                    ).length === 0
                  "
                  v-model="checkedContentSum"
                  @change="updateCheckboxValue($event, 'sum', showContent.code)"
                />
                <label for="contentSum">Sum</label>
                <input
                  type="checkbox"
                  id="contentOthers"
                  :disabled="
                    !checkViewYn ||
                    selectedContent?.list?.filter(
                      (list) =>
                        list.type === 0 ||
                        list.type === 1 ||
                        list.type === 2 ||
                        list.type === 3
                    ).length > 0
                  "
                  v-model="checkedContentOthers"
                  @change="
                    updateCheckboxValue($event, 'others', showContent.code)
                  "
                />
                <label for="contentOthers">Others</label>
              </div>
            </div>

          </div>
          <!-- 20230525 버튼 변경 { -->
          <div class="combine-button" v-show="parentMenuCode !== constants.CONSTANT_MENU_SOB2_CODE && parentMenuCode !== constants.CONSTANT_MENU_DOSAGE_CODE">
            <button class="sm-button-combine" @click="onClickBottom()">
              <span class="material-symbols-outlined">expand_more</span>
              <span style="padding-left: 12px">{{ $t("button.combine") }}</span>
            </button>
          </div>
          <!-- } 20230525 버튼 변경 -->

          <!-- 20230704 합치기 영역 감싸는 div 추가 { -->
          <div
            class="combine-wrapper"
            :style="{ display: combine?.list?.length > 0 ? 'block' : 'none' }"
          >
            <div>
              <!-- 20230612 스타일 추가 { -->
              <span style="font-size: 14px; font-weight: 700">
                {{ $t("label.summation_count") }} :
                {{ combine.list.length }}
              </span>
              <!-- } 20230612 스타일 추가 -->
            </div>
            <div class="combine">
              <div class="combine-list">
                <!-- 20230622 수정 { -->
                <div class="combine-list-searchgroup">
                  <input
                    class="combine-list-input"
                    :placeholder="$t('label.search_combi')"
                    v-model="searchCombiVal"
                    type="text"
                    @keydown.enter="fnSearchCombi()"
                  />
                  <button
                    class="btn combine-list-button"
                    @click="fnSearchCombi()"
                  >
                    {{ $t("button.search") }}
                  </button>
                </div>
                <!-- } 20230622 수정 -->
                <select
                  class="combine-select"
                  multiple
                  id="combineSelect"
                  v-model="selectedValues"
                  @contextmenu.prevent="
                    showContextMenu($event, showContent.code)
                  "
                  @click="combineBlur($event)"
                >
                  <option
                    v-for="(e, i) in combine.list"
                    v-bind:key="(e, i)"
                    :value="e.code + '_' + e.id"
                    :name="e.name"
                    :englishName="e.englishName"
                  >
                    {{
                      this.$i18n.locale == "en"
                        ? e.englishName || e.name + "(en)"
                        : e.name
                    }}
                  </option>
                </select>
              </div>
              <div
                v-if="isContextMenuVisible"
                class="context-menu"
                :style="{
                  top: contextMenuPosition.y + 'px',
                  left: contextMenuPosition.x + 'px',
                }"
              >
                <ul style="width: 80px">
                  <li @click="deleteItem($event)">
                    <button id="contextMenuDelete">
                      {{ $t("button.delete") }}
                    </button>
                  </li>
                </ul>
              </div>

              <div class="combine-name">
                <div class="combine-header" style="padding-left: 20px">
                  <!-- 20230612 스타일 추가 { -->
                  <span style="font-size: 14px; font-weight: 700">
                    {{ $t("label.combine_name") }}
                  </span>
                  <!-- } 20230612 스타일 추가 -->
                </div>

                <textarea
                  class="combine-text"
                  v-bind:disabled="noItemSelected"
                  ref="combineTextarea"
                  :value="
                    this.$i18n.locale === 'en'
                      ? combine.englishName
                      : combine.name
                  "
                  @input="
                    (combine.englishName = $event.target.value) &&
                      (combine.name = $event.target.value)
                  "
                ></textarea>
                <div class="combine-submit">
                  <div style="border-right: var(--border-line); width: 50%">
                    <button
                      @click="onClickCombine()"
                      v-bind:disabled="noItemSelected"
                    >
                      {{ $t("button.complete_confirm") }}
                    </button>
                  </div>
                  <div style="width: 50%">
                    <button
                      @click="onClickCancel()"
                      v-bind:disabled="noItemSelected"
                    >
                      {{ $t("button.cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- } 20230704 합치기 영역 감싸는 div 추가 -->
        </div>
        <div class="center">
          <button class="sm-button" 
            :class="{ 'disabled': combine?.list?.length > 0 }"
            :disabled="combine?.list?.length > 0"
            @click="onClick()">
            <span class="material-symbols-outlined">chevron_right</span>
            <span
              style="
                writing-mode: vertical-lr;
                text-orientation: upright;
                padding-top: 12px;
              "
              >{{ $t("button.put") }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <div style="z-index: 300">
    <!-- 20230612 z-index 값 수정 -->
    <OptionModal
      v-model:isOpen="state.isModalOpen"
      v-bind:parentMenu="parentMenu"
      v-bind:parentMenuCode="parentMenuCode"
    ></OptionModal>
    <ProductModal
      v-model:isProductModalOpen="isProductModalOpen"
    ></ProductModal>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import OptionModal from "@/components/OptionModal.vue";
import ProductModal from "@/components/ProductModal.vue";
import topBottomCodeMapping from "@/data/topBottomData.js";
import DirectInputAge from "@/components/DirectInputAge.vue";
import DirectInputOneQuantity from "@/components/DirectInputOneQuantity.vue";
import DirectInputPerDay from "@/components/DirectInputPerDay.vue";
import DirectInputTotalDay from "@/components/DirectInputTotalDay.vue";
import Calendar from "@/components/Calendar.vue";
import Select from "@/containerss/Select.vue";
import SearchContent from "@/containerss/SearchContent.vue";
import periodData from "@/data/periodData.js";
import periodCustomize from "@/data/periodCustomize.js";
import sob2PeriodData from "@/data/sob2PeriodData.js"

export default defineComponent({
  name: "Condition",
  props: ["parentMenu", "parentMenuCode"],
  components: {
    DirectInputAge,
    DirectInputOneQuantity,
    DirectInputPerDay,
    DirectInputTotalDay,
    AlertDialog,
    OptionModal,
    ProductModal,
    Calendar,
    Select,
    SearchContent,
  },
  setup() {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });
    const closeAlertDialog = (afterEvent) => {
      isAlertDialogOpen.value = false;
      // 합치기 이름 포커스
      if (afterEvent === "combi") {
        combineTextarea.value.focus();
      }
    };

    const { t } = useI18n();
    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();
    const {
      pivotDataList,
      showContent,
      selectedContent,
      selectedItemValue,
      combineItems,
      searchText,
      searchCombi,
      showIframe,
      btnDisable,
      sortInit,
      iframe,
      chooseOptions,
      chooseSelectedOption,
      rankSelectedOption,
      checkViewYn,
      checkedContentSum,
      checkedContentOthers,
      noItemSelected,
      itemEditModeYn,
      isProductModalOpen,
    } = storeToRefs(contentsStore);

    const state = reactive({
      isModalOpen: false,
    });

    const searchTextVal = ref(searchText);

    //검색
    const fnSearch = (code, type) => {
      if (typeof code === "undefined") {
        alertDialog.value.message = t("message.select_contents");

        isAlertDialogOpen.value = true;

        return;
      }

      const result = commonStore.validationSearchText(searchTextVal.value, constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code) ? 1 : 2);

      if (!result) {
        if (constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code)) alertDialog.value.message = t("message.validation_empty");
        else alertDialog.value.message = t("message.validation_length");

        isAlertDialogOpen.value = true;

        return;
      }

      contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
    };

    //검색지우기
    const fnSearchClean = (code, type) => {
      if (typeof code === "undefined") {
        searchTextVal.value = "";
        return;
      }
      const contentsStore = useContentsStore();
      if (showContent.value.search) {
        showContent.value = {
          cont: [],
          subContents: [],
          list: [],
        };
      }
      contentsStore.onClickPivot(code, type, false, parentMenuCode);
    };

    const selectedList = ref([]);
    //컨텐츠 항목별 선택시
    const onChange = (_selectedList) => {
      selectedList.value = _selectedList;
      hideContextMenu();
      hideContextMenuItem();
    };

    //선택한 항목 담기
    const onClick = () => {
      //합치기 편집중에는 항목에 추가 못함
      let itemEditMode = itemEditModeYn.value;
      if (itemEditMode) {
        alertDialog.value.message = t("message.validation_combi_edit");
        isAlertDialogOpen.value = true;
        return;
      }

      if ((selectedList.value?.length ?? 0) === 0) {
        alertDialog.value.message = t("message.select_items");
        isAlertDialogOpen.value = true;
        return;
      }
      
      //MAT 하나만 선택 가능
      const contentList = pivotDataList.value.find(p => p.code === selectedList.value[0].pCode).list.map((l) => {
        if (isNaN(l.id)) return l.id;
        const parts = l.id.split(":");
        return parts.length > 1 ? parts[0] : l.id;
      });
      const periodCustData = _.uniq([
        ...periodCustomize.years,
        ...periodCustomize.quarters,
        ...periodCustomize.months,
      ].map((l) => l.id));

      const difference = _.difference(contentList, periodCustData);

      if (difference.length !== contentList.length && contentList.length > 0) {
        alertDialog.value.message = t("message.analysis_mat_one");
        isAlertDialogOpen.value = true;
        return;
      }

      // let matCnt = 0;
      // const periodCustData = [
      //   ...periodCustomize.years,
      //   ...periodCustomize.quarters,
      //   ...periodCustomize.months,
      // ];
      // selectedList.value.forEach((select) => {
      //   const matIdLength = periodCustData.filter(
      //     (period) => period.id === select.id
      //   ).length;
      //   if (matIdLength > 0) {
      //     matCnt += 1;
      //   }
      // });

      // selectedContent.value.list.forEach((list) => {
      //   const matIdLength = periodCustData.filter(
      //     (period) => period.id === list.id
      //   ).length;
      //   if (matIdLength > 0) {
      //     matCnt += 1;
      //   }
      // });

      // if (matCnt > 1) {
      //   alertDialog.value.message = t("message.analysis_mat_one");
      //   isAlertDialogOpen.value = true;
      //   return;
      // }

      const list = selectedContent.value.list;
      const error = [];
      const errorEng = [];

      const msg = [];
      const msgEng = [];

      //기본 전체 삭제
      const removeIndex = selectedContent.value.list.findIndex(
        (c) =>
          c.code + "_" + c.type ===
            selectedList.value[0].pCode + "_98" ||
          c.code + "_" + c.type === selectedList.value[0].pCode + "_97"
      );
      if (removeIndex > -1) {
        selectedContent.value.list.splice(removeIndex, 1);
      }

      let metricShare = false;
      selectedList.value.forEach((select) => {
        const shareCode = constants.CONSTANT_METRICS_CODES.includes(
          select.pCode
        );
        const shareId = select.name.includes("(Share)")

        if (shareCode && shareId) {
          metricShare = true;
        }
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.code === code && l.id === id)) {
          list.push({ code, id, name, englishName, type });
          msg.push(name);
          msgEng.push(englishName);
        } else {
          error.push(name);
          errorEng.push(englishName);
        }
      });
      if (error.length > 0) {
        let existItem = error.join(",");
        let newItem = msg.join(",");
        if (localStorage.getItem("lang") == "en") {
          existItem = errorEng.join(",");
          newItem = msgEng.join(",");
        }

        if (msg.length > 0) {
          alertDialog.value.message = t("message.select_newItem", {
            existItem,
            newItem,
          });
          isAlertDialogOpen.value = true;
        } else {
          alertDialog.value.message = t("message.validation_existsItem", {
            existItem,
          });
          isAlertDialogOpen.value = true;
        }
      } else {
        //측정치 콘텐츠에서 share 가 포함된 상세 항목 있을 경우
        if (metricShare) {
          alertDialog.value.message = t("message.alert_metricShare");
          isAlertDialogOpen.value = true;
        }
      }
    };

    const focusout = (event) => {
      const element = document.getElementById(`contextMenuItem`);
      if (element.contains(event.relatedTarget)) {
        return;
      }
      hideContextMenuItem();
    }

    const searchCombiVal = ref(searchCombi);
    //합치기 검색
    const fnSearchCombi = (event) => {
      if (combine.value.list.length === 0) {
        alertDialog.value.message = t("message.search_no_item");
        isAlertDialogOpen.value = true;
        return;
      }

      const combineSelect = document.querySelectorAll(
        "#combineSelect > option"
      );
      const regex = / /gi;
      let resVal = searchCombiVal.value.replace(regex, "");

      if (resVal.length < 1) {
        for (let i = 0; i < combineSelect.length; i++) {
          combineSelect[i].style.display = "block";
        }
      } else {
        for (let i = 0; i < combineSelect.length; i++) {
          const result = combineSelect[i]
            .getAttribute("name")
            .includes(searchCombiVal.value);
          if (!result) {
            combineSelect[i].style.display = "none";
          }
        }
      }
    };

    //합치기 검색지우기
    const fnSearchCombiClean = () => {
      if (combine.value.list.length > 0) {
        const combineSelect = document.querySelectorAll(
          "#combineSelect > option"
        );
        for (let i = 0; i < combineSelect.length; i++) {
          combineSelect[i].style.display = "block";
        }
      }
      searchCombiVal.value = "";
    };

    const combine = ref(combineItems);
    //합치기 영역으로 선택
    const onClickBottom = () => {
      if (
        selectedList.value.length === 0 ||
        typeof selectedList.value === "undefined" ||
        selectedList.value.length === 0
      ) {
        alertDialog.value.message = t("message.combi_no_item");
        isAlertDialogOpen.value = true;
        return;
      }

      if (
        constants.CONSTANT_METRICS_CODES.includes(showContent.value.code) ||
        constants.CONSTANT_GENERIC_CODE === showContent.value.code
      ) {
        const contentName = localStorage.getItem("lang") == "en" ? showContent.value.englishName : showContent.value.name;
        alertDialog.value.message = t("message.validation_combi", {
          contentName,
        });
        isAlertDialogOpen.value = true;
        return;
      }

      const list = combine.value.list;
      const error = [];
      selectedList.value.forEach((select) => {
        select.type = 1;
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.id === id)) {
          list.push({ code, id, name, englishName, type });
        } else {
          error.push({ name: name, englishName: englishName });
        }
      });

      combine.value.name = list.map((l) => l.name).join(" + ");
      combine.value.englishName = list.map((l) => l.englishName).join(" + ");

      if (error.length > 0) {
        const errorItems = error;
        let itemName = "";
        let itemEnglishName = "";
        errorItems.forEach((item, idx) => {
          if (idx > 0) {
            itemName += ",";
            itemEnglishName += ",";
          }
          itemName += item.name;
          itemEnglishName += item.englishName;
        });

        let item = itemName;
        if (localStorage.getItem("lang") == "en") {
          item = itemEnglishName;
        }
        alertDialog.value.message = t("message.validation_existsCombiItem", {
          item,
        });
        isAlertDialogOpen.value = true;
      }
      noItemSelected.value = selectedList.value.every(
        (list) => list.length === 0
      );
      selectedList.value = [];
    };

    const combineTextarea = ref(null);
    //합치기 완료 하기
    const onClickCombine = () => {
      if (combine.value.list.length === 0) return;
      let existsYn = false;
      let itemEditMode = itemEditModeYn.value;

      const regex = / /gi;
      let combineName = combine.value.name.replace(regex, "");
      let combineEnglishName = combine.value.englishName.replace(regex, "");
      //합치기 이름이 공백 일 경우
      if (combineName.length < 1 || combineEnglishName.length < 1) {
        alertDialog.value.message = t("message.validation_combiName_empty");
        alertDialog.value.afterEvent = "combi";
        combine.value.name = "";
        combine.value.englishName = "";
        isAlertDialogOpen.value = true;
        return;
      }

      //합치기 이름이 100자를 넘을 경우
      if (
        combine.value.name.length > 100 &&
        combine.value.englishName.length > 100
      ) {
        alertDialog.value.message = t("message.validation_combiName_100over");
        alertDialog.value.afterEvent = "combi";
        //combine.value.name = "";
        //combine.value.englishName = "";
        isAlertDialogOpen.value = true;
        return;
      }

      //최초입력시 (수정이 아닐때)
      if (!itemEditMode) {
        //기본 전체 삭제
        const removeIndex = selectedContent.value.list.findIndex(
          (c) =>
            c.code + "_" + c.type ===
              selectedContent.value.list[0].code + "_98" ||
            c.code + "_" + c.type === selectedContent.value.list[0].code + "_97"
        );
        if (removeIndex > -1) {
          selectedContent.value.list.splice(removeIndex, 1);
        }

        selectedContent.value.list.forEach((select) => {
          let item = combine.value.name;
          if (localStorage.getItem("lang") == "en") {
            item = combine.value.englishName;
          }
          if (select.name === "(+) " + item) {
            alertDialog.value.message = t("message.validation_existsItemName", {
              item,
            });
            isAlertDialogOpen.value = true;
            existsYn = true;
          }
        });

        if (!existsYn) {
          selectedContent.value.list.push({
            code: combine.value.list.map((l) => l.code),
            id: combine.value.list.map((l) => l.id),
            itemName: combine.value.list.map((l) => l.name),
            itemEnglishName: combine.value.list.map((l) => l.englishName),
            name: "(+) " + combine.value.name,
            englishName: "(+) " + combine.value.englishName,
            type: 1,
          });
          selectedContent.value.custom = true;
          noItemSelected.value = true;
          combine.value = {
            name: "",
            englishName: "",
            list: [],
          };
        }else{
          return ;
        }
      } else {
        //수정 일 떄
        const selContent = selectedContent.value.list;
        const selItemValue = selectedItemValue.value;

        selContent
          .filter(
            (item) =>
              item.code !== selItemValue.code && item.id !== selItemValue.id
          )
          .forEach((select) => {
            let item = combine.value.name;
            if (localStorage.getItem("lang") == "en") {
              item = combine.value.englishName;
            }
            if (select.name === "(+) " + item) {
              alertDialog.value.message = t(
                "message.validation_existsItemName",
                { item }
              );
              isAlertDialogOpen.value = true;
              existsYn = true;
            }
          });

        if (!existsYn) {
          const foundItem = selContent.find(
            (item) =>
              item.code === selItemValue.code && item.id === selItemValue.id
          );

          if (foundItem) {
            foundItem.code = combine.value.list.map((l) => l.code);
            foundItem.id = combine.value.list.map((l) => l.id);
            foundItem.itemName = combine.value.list.map((l) => l.name);
            foundItem.itemEnglishName = combine.value.list.map(
              (l) => l.englishName
            );
            foundItem.name = "(+) " + combine.value.name;
            foundItem.englishName = "(+) " + combine.value.englishName;
            foundItem.type = 1;
          }
          selectedContent.value.custom = true;
          noItemSelected.value = true;
          combine.value = {
            name: "",
            englishName: "",
            list: [],
          };
        }else  {
          return;
        }
      }
      onClickCancel();
    };

    //합치기 취소 하기
    const onClickCancel = () => {
      selectedList.value = [];
      noItemSelected.value = true;
      combine.value = {
        name: "",
        englishName: "",
        list: [],
      };
      searchCombiVal.value = "";
      itemEditModeYn.value = false;
    };

    const isCheckedSum = ref(false);
    const isCheckedOthers = ref(false);
    //선택하지 않으면 선택시
    const onChooseChange = (event, type, code) => {
      // console.log('onChooseChange', event.target.value, type, code, rankSelectedOption.value);

      const chooseChkOption = document.querySelector(
        "#choose_" + code + " > option:checked"
      );
      const rankChkOption = document.querySelector(
        "#rank_" + code + " > option:checked"
      );

      //해당 컨텐츠 옵션 수정
      const chooseName = chooseChkOption.getAttribute("name");
      const chooseEnglishName = chooseChkOption.getAttribute("englishName");
      let rankOptionVal = rankChkOption?.value;
      let rankName = rankChkOption?.getAttribute("name");
      let rankEnglishName = rankChkOption?.getAttribute("englishName");

      const value = event.target.value;
      if (type === "rank_custom" && value) {
        const isTop = value.startsWith("Top");
        const isBottom = value.startsWith("Bottom");
        let number = parseInt(value.replace("Top", "").replace("Bottom", ""));
        number = isNaN(number) ? -1 : number;

        console.log(isTop, isBottom, number);
        if (number > 0) {
          if (isTop) {
            rankOptionVal = "Top " + number;
            rankName = "Top " + number;
            rankEnglishName = "Top " + number;
          } else if (isBottom) {
            rankOptionVal = "Bottom " + number;
            rankName = "Bottom " + number;
            rankEnglishName = "Bottom " + number;
          }
        } else {
          rankOptionVal = "";
          rankName = "전체";
          rankEnglishName = "Total";
        }
      }

      if (rankOptionVal === "" || rankOptionVal === null) {
        isCheckedSum.value = false;
        isCheckedOthers.value = false;
        //전체 선택시 Sum /  Others 삭제
        selectedContent.value.list = selectedContent.value.list.filter(
          (list) => list.type !== 99
        );
      } else {
        const chooseOption = chooseOptions.value.find(
          (r) => r.contCode === code
        );
        isCheckedSum.value = chooseOption.chkSumVal;
        isCheckedOthers.value = chooseOption.chkOthersVal;
      }


      const foundItem = chooseOptions.value.find(
        (chooseOption) => chooseOption.contCode === code
      );
      if (foundItem) {
        foundItem.contOptionVal = chooseChkOption.value;
        foundItem.contOptionName = chooseName;
        foundItem.contOptionEnglishName = chooseEnglishName;
        foundItem.rankOptionVal = rankOptionVal;
        foundItem.rankOptionName = rankName;
        foundItem.rankOptionEnglishName = rankEnglishName;
        foundItem.chkSumVal = isCheckedSum.value;
        foundItem.chkOthersVal = isCheckedOthers.value;
      }

      const foundDefaultItem = selectedContent.value.list.find(
        (defaultItem) =>
          defaultItem.code + "_" + defaultItem.type === code + "_98"
      );
      if (foundDefaultItem) {
        foundDefaultItem.id = chooseChkOption.value;
        foundDefaultItem.name = chooseName + "_" + rankName;
        foundDefaultItem.englishName =
          chooseEnglishName + "_" + rankEnglishName;
        foundDefaultItem.type = 98;
      }
    };

    //sum /others 체크
    const updateCheckboxValue = (event, type, code) => {
      const chooseOption = chooseOptions.value.find((r) => r.contCode === code);
      if (type === "sum") {
        isCheckedSum.value = event.target.checked;
        chooseOption.chkSumVal = isCheckedSum.value;
        if (chooseOption.chkSumVal) {
          const sumOption = {
            code: code,
            id: "Sum",
            name: "Sum",
            englishName: "Sum",
            type: 99,
          };
          selectedContent.value.list.splice(0, 0, sumOption);
        } else {
          const removeIndex = selectedContent.value.list.findIndex(
            (c) => c.code + "_" + c.id === c.code + "_Sum"
          );
          if (removeIndex > -1) {
            selectedContent.value.list.splice(removeIndex, 1);
          }
        }
      } else {
        isCheckedOthers.value = event.target.checked;
        chooseOption.chkOthersVal = isCheckedOthers.value;

        if (chooseOption.chkOthersVal) {
          const othersOption = {
            code: code,
            id: "Others",
            name: "Others",
            englishName: "Others",
            type: 99,
          };

          if (chooseOption.chkSumVal) {
            selectedContent.value.list.splice(1, 0, othersOption);
          } else {
            selectedContent.value.list.splice(0, 0, othersOption);
          }
        } else {
          const removeIndex = selectedContent.value.list.findIndex(
            (c) => c.code + "_" + c.id === c.code + "_Others"
          );
          if (removeIndex > -1) {
            selectedContent.value.list.splice(removeIndex, 1);
          }
        }
      }
    };

    const onBtnDisable = () => {
      btnDisable.value = true;
      showIframe.value = true;
    };

    //모달 상태
    const openModal = () => {
      state.isModalOpen = true;
    };
    const closeModal = () => {
      state.isModalOpen = false;
    };
    const openProductModal = () => {
      const contentsStore = useContentsStore();
      contentsStore.onClickSearchContentModalItem(
        showContent.value.code,
        showContent.value.type,
        "",
        true
      );
      isProductModalOpen.value = true;
    };
    const closeProductModal = () => {
      isProductModalOpen.value = false;
    };

    //오른쪽 마우스 기능
    const isContextMenuVisible = ref(false);
    const contextMenuPosition = reactive({ x: 0, y: 0 });
    const selectedValues = ref([]);
    const removeSelectedValues = ref([]);

    const showContextMenu = (event, pCode) => {
      // event.preventDefault(); // 기본 동작을 중단시킵니다.
      const selLength = selectedValues.value.length;
      if (selLength > 0) {
        isContextMenuVisible.value = true;
        contextMenuPosition.x = event.clientX;
        contextMenuPosition.y = event.clientY;
        removeSelectedValues.value = selectedValues.value;
      }
    };

    const deleteItem = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      // 선택된 기능에 따른 동작을 수행합니다.
      if (removeSelectedValues.value.length > 0) {
        removeSelectedValues.value.forEach((sel) => {
          const removeIndex = combine.value.list.findIndex(
            (c) => c.code + "_" + c.id === sel
          );
          combine.value.list.splice(removeIndex, 1);
        });

        combine.value.name = combine.value.list.map((l) => l.name).join(" + ");
        combine.value.englishName = combine.value.list
          .map((l) => l.englishName)
          .join(" + ");
        selectedValues.value = [];
        removeSelectedValues.value = [];
      } else {
        alertDialog.value.message = t("message.validation_deleteItem");
        isAlertDialogOpen.value = true;
      }
      hideContextMenu();

      //합치기 항목이 없으면 합치기 초기화
      if (combine.value.list.length === 0) {
        onClickCancel();
      }
    };

    const combineBlur = (event) => {
      hideContextMenu();
      hideContextMenuItem();
    };

    const hideContextMenu = () => {
      isContextMenuVisible.value = false;
    };

    //오른쪽 마우스 기능
    const isContextMenuVisibleItem = ref(false);
    const contextMenuPositionItem = reactive({ x: 0, y: 0 });
    const isContextMenuButtonMore = ref(false);
    const isContextMenu3MRollingButtonMore = ref(false);
    const showContextMenuItem = (event, data, i) => {
      const { code } = selectedList.value[0];

      isContextMenuVisibleItem.value = true;
      contextMenuPositionItem.x = event.clientX;
      contextMenuPositionItem.y = event.clientY;

      const isCustom = selectedList.value.reduce((acc, cur) => acc || isNaN(cur.id), false);
      isContextMenu3MRollingButtonMore.value = !isCustom && constants.CONSTANT_MONTH_PERIOD_CODES === code;
      if (selectedList.value.length > 0) {
        const button3MRolling = document.querySelector("#button3MRolling");
        button3MRolling.textContent = "3M Rolling";

        if (typeof selectedList.value !== "undefined") {
          if (selectedList.value.length === 1) {

            const id = selectedList.value[0].id;
            const name = selectedList.value[0].name;
            const englishName = selectedList.value[0].englishName;
            const quartersCustCnt = periodData.quarters.filter(
              (period) => period.id === id
            ).length;
            const monthsCustCnt = periodData.months.filter(
              (period) => period.id === id
            ).length;

            if (
              constants.CONSTANT_CUSTMORE_PERIOD_CODES.includes(code) &&
              quartersCustCnt + monthsCustCnt === 0
            ) {
              isContextMenuButtonMore.value = true;

              let btnLabel1 = name + " ~ 최근";
              let btnLabel2 = name + " 이전";
              if (localStorage.getItem("lang") == "en") {
                btnLabel1 = englishName + " ~ Lately";
                btnLabel2 = "Before " + englishName;
              }
              const buttonCurrent = document.querySelector("#buttonCurrent");
              buttonCurrent.textContent = btnLabel1;
              const button3MRolling = document.querySelector("#button3MRolling");
              button3MRolling.textContent = name + " 3M Rolling";
              const button3MRollingLately = document.querySelector("#button3MRollingLately");
              button3MRollingLately.textContent = btnLabel1 + " (Rolling)";
              const buttonMAT = document.querySelector("#buttonMAT");
              buttonMAT.textContent = btnLabel2 + " MAT";
              const button2MAT = document.querySelector("#button2MAT");
              button2MAT.textContent = btnLabel2 + " 2MAT";
              const button3MAT = document.querySelector("#button3MAT");
              button3MAT.textContent = btnLabel2 + " 3MAT";
              const button5MAT = document.querySelector("#button5MAT");
              button5MAT.textContent = btnLabel2 + " 5MAT";
              const list = showContent.value.list.find(p => p.code === code).list;
              const periodList = list.filter((l) => l.parentId !== "");
              periodList.sort((a, b) => {
                let x = a.id.toLowerCase();
                let y = b.id.toLowerCase();
                return x.localeCompare(y);
              });

              const periodIndex = periodList.findIndex((l) => l.id === id);
              //분기 일 경우
              let mst = 3;
              let mst2 = 7;
              let mst3 = 11;
              let mst5 = 19;
              //월 일 경우
              if (constants.CONSTANT_QUARTER_PERIOD_CODES !== code) {
                mst = 11;
                mst2 = 23;
                mst3 = 35;
                mst5 = 59;
              }
              buttonMAT.disabled = false;
              buttonMAT.style.cursor = "pointer";
              button2MAT.disabled = false;
              button2MAT.style.cursor = "pointer";
              button3MAT.disabled = false;
              button3MAT.style.cursor = "pointer";
              button5MAT.disabled = false;
              button5MAT.style.cursor = "pointer";

              if (periodIndex - mst < 0) {
                buttonMAT.disabled = true;
                buttonMAT.style.cursor = "default";
                button2MAT.disabled = true;
                button2MAT.style.cursor = "default";
                button3MAT.disabled = true;
                button3MAT.style.cursor = "default";
                button5MAT.disabled = true;
                button5MAT.style.cursor = "default";
              }
              if (periodIndex - mst >= 0 && periodIndex - mst2 < 0) {
                button2MAT.disabled = true;
                button2MAT.style.cursor = "default";
                button3MAT.disabled = true;
                button3MAT.style.cursor = "default";
                button5MAT.disabled = true;
                button5MAT.style.cursor = "default";
              }
              if (periodIndex - mst2 >= 0 && periodIndex - mst3 < 0) {
                button3MAT.disabled = true;
                button3MAT.style.cursor = "default";
                button5MAT.disabled = true;
                button5MAT.style.cursor = "default";
              }
              if (periodIndex - mst3 >= 0 && periodIndex - mst5 < 0) {
                button5MAT.disabled = true;
                button5MAT.style.cursor = "default";
              }
            } else {
              isContextMenuButtonMore.value = false;
            }
          }
        }
      }

      const element = document.getElementById(`contextMenuItem`);
      if (element) setTimeout(() => element.focus(), 0);
    };

    const matData = ref([]);
    const putMAT = (event, type) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.

      //합치기 편집중에는 항목에 추가 못함
      let itemEditMode = itemEditModeYn.value;
      if (itemEditMode) {
        alertDialog.value.message = t("message.validation_combi_edit");
        isAlertDialogOpen.value = true;
        return;
      }

      if (selectedList.value.length === 0) {
        alertDialog.value.message = t("message.select_items");
        isAlertDialogOpen.value = true;
        return;
      }
      
      //MAT 하나만 선택 가능
      const contentList = pivotDataList.value.find(p => p.code === selectedList.value[0].pCode).list.map((l) => {
        if (isNaN(l.id)) return l.id;
        const parts = l.id.split(":");
        return parts.length > 1 ? parts[0] : l.id;
      });
      const periodCustData = _.uniq([
        ...periodCustomize.years,
        ...periodCustomize.quarters,
        ...periodCustomize.months,
      ].map((l) => l.id));

      const difference = _.difference(contentList, periodCustData);

      if (difference.length !== contentList.length && contentList.length > 0) {
        alertDialog.value.message = t("message.analysis_mat_one");
        isAlertDialogOpen.value = true;
        return;
      }

      let matName = selectedList.value[0].name;
      let matEnglishName = selectedList.value[0].englishName;
      let matid = "";

      //분기인 경우
      let periodArrData = periodCustomize.quarters;
      if (
        selectedList.value[0].code !==
        constants.CONSTANT_QUARTER_PERIOD_CODES
      ) {
        //월 인 경우
        periodArrData = periodCustomize.months;
      }

      if (type === "CUR") {
        matName = matName + " ~ 최근";
        matEnglishName = matEnglishName + " ~ Lately";
        matid = periodArrData[1].id;
      }
      if (type === "3MRolling") {
        matName = matName + " 3M Rolling";
        matEnglishName = matEnglishName + " 3M Rolling";
        matid = periodArrData[24].id;
      }
      if (type === "3MRollingLately") {
        matName = matName + " ~ 최근 3M Rolling";
        matEnglishName = matEnglishName + " ~ Lately 3M Rolling";
        matid = periodArrData[25].id;
      }
      if (type === "MAT") {
        matName = matName + " 이전 MAT";
        matEnglishName = "Before " + matEnglishName + " MAT"; //분기 인 경우
        matid = periodArrData[12].id;
      }
      if (type === "2MAT") {
        matName = matName + " 이전 2MAT";
        matEnglishName = "Before " + matEnglishName + " 2MAT";
        matid = periodArrData[9].id;
      }
      if (type === "3MAT") {
        matName = matName + " 이전 3MAT";
        matEnglishName = "Before " + matEnglishName + " 3MAT";
        matid = periodArrData[6].id;
      }
      if (type === "5MAT") {
        matName = matName + " 이전 5MAT";
        matEnglishName = "Before " + matEnglishName + " 5MAT";
        matid = periodArrData[3].id;
      }

      const values = selectedList.value.map((select) => ({
        pCode: select.pCode,
        code: select.code,
        id: matid + ":" + select.id,
        name: type === "3MRolling" ? `${select.name} 3M Rolling` : matName,
        englishName: type === "3MRolling" ? `${select.englishName} 3M Rolling` : matEnglishName,
        type: 0,
      }));
      matData.value = values;

      const list = selectedContent.value.list;
      const error = [];
      const errorEng = [];

      const msg = [];
      const msgEng = [];

      //기본 전체 삭제
      const removeIndex = selectedContent.value.list.findIndex(
        (c) =>
          c.code + "_" + c.type ===
            selectedList.value[0].pCode + "_98" ||
          c.code + "_" + c.type === selectedList.value[0].pCode + "_97"
      );
      if (removeIndex > -1) {
        selectedContent.value.list.splice(removeIndex, 1);
      }

      matData.value.forEach((select) => {
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.id === id)) {
          list.push({ code, id, name, englishName, type });
          msg.push(name);
          msgEng.push(englishName);
        } else {
          error.push(name);
          errorEng.push(englishName);
        }
      });
      if (error.length > 0) {
        let existItem = error.join(",");
        let newItem = msg.join(",");
        if (localStorage.getItem("lang") == "en") {
          existItem = errorEng.join(",");
          newItem = msgEng.join(",");
        }

        if (msg.length > 0) {
          alertDialog.value.message = t("message.select_newItem", {
            existItem,
            newItem,
          });
          isAlertDialogOpen.value = true;
        } else {
          alertDialog.value.message = t("message.validation_existsItem", {
            existItem,
          });
          isAlertDialogOpen.value = true;
        }
      }

      matData.value = [];
      hideContextMenuItem();
    };

    const putItem = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      onClick();
      hideContextMenuItem();
    };

    const combinePutItem = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      onClickBottom();
      hideContextMenuItem();
    };

    const hideContextMenuItem = () => {
      isContextMenuVisibleItem.value = false;
      isContextMenuButtonMore.value = false;
    };

    const isListDisabled = ref(false);
    const listIndex = ref(0);
    const onListDisabled = (event, idx, val) => {
      isListDisabled.value = val;
      listIndex.value = idx;
    };

    onMounted(() => {
      searchTextVal.value = "";
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      showContent,
      selectedContent,
      selectedItemValue,
      chooseOptions,
      chooseSelectedOption,
      rankSelectedOption,
      checkedContentSum,
      checkedContentOthers,
      checkViewYn,

      fnSearch,
      fnSearchClean,
      onChange,
      onClick,
      onClickBottom,
      focusout,

      searchCombiVal,
      fnSearchCombi,
      fnSearchCombiClean,

      showIframe,
      btnDisable,
      sortInit,
      iframe,
      selectedList,
      combine,
      noItemSelected,
      itemEditModeYn,
      onClickCombine,
      onClickCancel,
      combineTextarea,
      searchTextVal,

      state,
      rankInputVal: "전체",
      isProductModalOpen,

      isCheckedSum,
      isCheckedOthers,

      onChooseChange,
      updateCheckboxValue,

      onBtnDisable,
      openModal,
      closeModal,
      openProductModal,
      closeProductModal,

      selectedValues,
      removeSelectedValues,
      isContextMenuVisible,
      contextMenuPosition,
      showContextMenu,
      deleteItem,
      combineBlur,
      hideContextMenu,

      isContextMenuVisibleItem,
      isContextMenuButtonMore,
      isContextMenu3MRollingButtonMore,
      contextMenuPositionItem,
      showContextMenuItem,
      putMAT,
      putItem,
      combinePutItem,

      topBottomCodeMapping,

      isListDisabled,
      listIndex,
      onListDisabled,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line);
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.condition .result {
  display: none;
  height: 100%;
  /* max-height: 580px; */
  max-width: 980px;

  overflow: auto;
  white-space: pre-wrap;
  border-collapse: 0;
}
.condition .result.active {
  display: block;
}

.source {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.source.active {
  display: flex;
}
.title {
  display: flex;
}

.source-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.source-content .left {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}
.source-content .center {
  /* margin-top: 40px; */ /* 20230704 수정 */
  display: flex;
  width: fit-content;
  height: 100%;
  /* height: 88%; */ /* 20230704 수정 */
  padding: 0 10px;
}
.source-content .right {
  display: flex;
  min-width: 180px;
  max-width: 180px;
  height: calc(100% - 35px);
  padding: 10px 0px;
  padding-top: 35px;
}
.source-content .right2 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: fit-content;
  height: 100%;
}

.sm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  width: 26px;
  height: 86px;
  margin-top: -26px; /* 20230704 추가 */
  font-size: 13px;
  cursor: pointer;
  border: var(--border-line);
  border-radius: 26px;
  transform: translateY(-50%);
  box-shadow: 2px 2px 6px rgba(6, 10, 19, 0.1);
}
.sm-button .material-symbols-outlined {
  position: absolute;
  left: 50%;
  top: 4px;
  width: 18px;
  height: 18px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  border-radius: 50%;
  background-color: #545454;
  transform: translateX(-50%);
}

.sm-button-combine {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px;
  height: 26px;
  font-size: 13px;
  cursor: pointer;
  border: var(--border-line);
  border-radius: 26px;
  box-shadow: 2px 2px 6px rgba(6, 10, 19, 0.1);
}

/* 20230612 추가 */
.sm-button:hover,
.sm-button-combine:hover {
  background-color: #f5f5f5;
}

.disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}
.disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}
.disabled .material-symbols-outlined {
  background-color: rgba(16, 16, 16, 0.3);
}

.sm-button-combine .material-symbols-outlined {
  position: absolute;
  left: 4px;
  top: 50%;
  width: 18px;
  height: 18px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  border-radius: 50%;
  background-color: #545454;
  transform: translateY(-50%);
}

.search-bar {
  /*width: 103%;*/
  display: flex;
  justify-content: space-between;
}
.search-bar .search-group {
  width: 70%;
  display: flex;
}
.search-bar .btn-group {
  display: flex;
}

.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
}
.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}

.combine-button {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.combine {
  display: flex;
  height: 140px;
  width: 100%;
  padding-top: 5px;
}
.combine-list {
  flex: 1 1 auto;
  height: 100%;
  background-color: #fff;
  /* border: var(--border-line); */
}

/* 202030622 수정 */
.combine-list-searchgroup {
  display: flex;
  align-items: center;
}

/* 202030622 수정 */
.combine-list-searchgroup .combine-list-input {
  flex: 1 1 0;
  height: 36px;
  border-radius: initial;
  border-top-left-radius: 4px;
  border-bottom: none;
  border-right: none;
  padding-left: 28px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27512%27 height=%27512%27 viewBox=%270 0 512 512%27%3E%3Ctitle%3Eionicons-v5-f%3C/title%3E%3Cpath d=%27M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z%27 style=%27fill:none;stroke:%23999;stroke-miterlimit:10;stroke-width:64px%27/%3E%3Cline x1=%27338.29%27 y1=%27338.29%27 x2=%27448%27 y2=%27448%27 style=%27fill:none;stroke:%23999;stroke-linecap:round;stroke-miterlimit:10;stroke-width:64px%27/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 6px center;
}

/* 202030622 수정 */
.combine-list-searchgroup .combine-list-button {
  width: 78px;
  height: 36px;
  background-color: #8a8a8a;
  border-color: #8a8a8a;
  border-radius: 0;
  color: #fff;
}

.combine-name {
  display: flex;
  flex-direction: column;
  width: 400px;

  /* border: 1px solid;
  background-color: #e7e7e7; */
  background-color: #f5f5f5;
  border: var(--border-line);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* 202030622 수정 */
.combine-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  height: 35px;
}

.combine-text {
  flex: 1 1 auto;
  resize: none;
  border-right: none;
  border-left: none;
  border-top: var(--border-line);
  border-bottom: var(--border-line);
  padding: 5px;
}

.combine-text:focus {
  outline: none;
}

.combine select {
  width: 100%;
  /* height: 100%; */
  height: 73%;
  border: var(--border-line);
  padding: 8px;
  border-right: none;
  /* border-top-left-radius: 4px; */
  border-bottom-left-radius: 4px;
}
.combine select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.combine select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
.combine-submit {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
}
.combine-submit button {
  width: 100%;
  height: 20px;
}

.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}

.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}

.selected .header2 {
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}

/* 마우스 우큭릭 */
.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}
.context-menu:focus-visible {
  outline: none;
}
.context-menu ul {
  width: 170px;
}
.context-menu ul + ul {
  border-top: 1px solid #ddd;
}
.context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}
.context-menu li:hover {
  background-color: #efefef;
}

.context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}

/* selectbox editor */
.select-editable {
  position: relative;
  background-color: white;
  border: solid grey 1px;
  width: 100px;
  height: 18px;
  margin-left: 5px;
}
.select-editable select {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 14px;
  border: none;
  width: 100px;
}
.select-editable input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  padding: 1px;
  font-size: 14px;
  border: none;
}
.select-editable select:focus,
.select-editable input:focus {
  outline: none;
}

/* 20230612 추가 */
#divContentSum {
  display: flex;
  align-items: center;
}

#divContentSum label + input {
  margin-left: 16px;
}
#divContentSum label {
  font-size: 14px;
  font-weight: 500;
}

/* 숫자만 입력 가능 */
.no-spin-button::-webkit-inner-spin-button,
.no-spin-button::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spin-button {
  appearance: textfield;
}

.rank_group {
  position: relative;
  display: inline-block;
  width: max-content;
}

.rank_group input {
  position: absolute;
  width: 80px;
  top: 6px;
  left: 9px;
  padding: 0px;
  line-height: 1.3;
  border: 0px;
}
</style>
